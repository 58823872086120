import React from "react";

import {
  OneSiteLink,
  PageSections,
  SEOMeta,
} from "@interactive-investor/onestack-web-shared";

import Layout from "../components/layout";

const HomePage = () => (
  <>
    <SEOMeta
      meta={{
        canonical_url: process.env.GATSBY_SITE_URL || "",
        description:
          "interactive investor's research tools - Virtual Portfolio, Newsletters",
        robots: "",
        title: process.env.GATSBY_SITE_NAME || "",
      }}
      titleSuffix=""
    />
    <Layout>
      <PageSections
        sections={[
          {
            type: "wysiwyg",
            value: (
              <div>
                <h1>Welcome to interactive investor's research tools</h1>
                <p>
                  Research accounts allow you to build a virtual investment
                  portfolio and gives you access to our research, analysis and
                  award-winning newsletters.
                </p>
                <ul>
                  <li>
                    <OneSiteLink
                      title="Virtual Portfolio"
                      to="/virtual-portfolio"
                    >
                      Virtual Portfolio
                    </OneSiteLink>
                  </li>
                  <li>
                    <OneSiteLink
                      title="Newsletters"
                      to="/newsletter-preferences"
                    >
                      Newsletters
                    </OneSiteLink>
                  </li>
                </ul>
              </div>
            ),
          },
        ]}
      />
    </Layout>
  </>
);

export default HomePage;
